import { useEffect, useState, memo } from 'react';
import PubSub from 'pubsub-js'
import InputMask from 'react-input-mask';
import swal from '@sweetalert/with-react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Router from "next/router";

import Slide from '@material-ui/core/Slide';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@material-ui/core/DialogContentText';
import Drawer from '@material-ui/core/Drawer';

import Service from "../services/service";
import Telefones from "../components/telefones";
import Image from "../components/image";
import AvatarGroup from "../components/avatarGroup";
import ModalVideoApresentacao from "../components/modalVideoApresentacao";

import {UserContext} from "../components/userContext";
import { logEvent } from '../utils/analytics';
import { connect } from 'react-redux';
import actions from '../redux/actions';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import NearMeIcon from '@material-ui/icons/NearMe';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Icon from '@material-ui/core/Icon';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MicIcon from '@material-ui/icons/Mic';

import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon
} from "react-share";

const Disparo = (props) => {


  const subscribeModal = PubSub.subscribe('openModalDisparo', (type, model) => {
    openPropose(event)
  });

  const trabalhar = () => {
    Router.push({ pathname: `/register`})
  }

  const textToNumber = {
    'um': 1,
    'uma': 1,
    'dois': 2,
    'duas': 2,
    'três': 3,
    'quatro': 4,
    'cinco': 5,
    'seis': 6,
    'sete': 7,
    'oito': 8,
    'nove': 9,
    'dez': 10,
  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition({
    commands: [{
        command: 'reiniciar',
        callback: ({ resetTranscript }) => resetTranscript()
      },
      {
        command: 'parar',
        callback: () => SpeechRecognition.stopListening()
      },
      {
        command: ['* Quartos', '* Quarto'],
        callback: (n) => {

          setState({
            ...state,
            form: {
              ...state.form,
              'bedrooms': textToNumber[n]
            }
          });
        }
      },
      {
        command: ['* Banheiros', '* Banheiro'],
        callback: (n) => {

          setState({
            ...state,
            form: {
              ...state.form,
              'bathrooms': textToNumber[n]
            }
          });
        }
      },
      {
        command: ['* Salas', '* Sala'],
        callback: (n) => {

          setState({
            ...state,
            form: {
              ...state.form,
              'rooms': textToNumber[n]
            }
          });
        }
      },
      {
        command: ['* Cozinhas', '* Cozinha'],
        callback: (n) => {

          setState({
            ...state,
            form: {
              ...state.form,
              'kitchen': textToNumber[n]
            }
          });
        }
      }
    ]
  });

  let colaboradores = props.disparos;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const url = `https://odete.com.br`;
  const titleShare = `Acabei de usar e estou recomendando a Odete.com.br - A maior plataforma de diarista do Brasil`;

  const [marks, setMarks] = useState([]);
  const [toogles, setHandleToogles] = useState({    
      modalPropose: false,
      backdrop: true,
      step: 'information',
      people: [],
      drawerComparacao: false
  });

  const [state, setState] = useState({
    form: {
      nome: "",
      sobrenome: "",
      historico:"",
      email: "",
      senha: "",
      cep: "",
      endereco: "",
      numero: "",
      uf: "",
      imagem: "",
      cidade: "",
      bairro: "",
      valor: "",
      loc: [],
      uid: "",
      referencia: "",
      _id: "",
      token: "",
      tipoServico: '',
      startDate: new Date(),
      endDate: new Date(),
      paused: false,
      maxDistance: 1,
      telefones: [{
          operadora: "",
          telefone: "",
          whatsapp: false
      }],
      diasEmAberto: '',
      servicos: [],
      bedrooms: '',
      bathrooms: '',
      rooms: '',
      kitchen: '',
      date: Service.formatDate(new Date(), 'yyyy-MM-dd'),
      minDate: Service.formatDate(new Date(), 'yyyy-MM-dd'),
      conducaoAlimentacao: '',
      horario: '',
      precisa_produtos: false,
      frequencia: "",
      servicos_especiais: [],
      informacoes_especiais: [],
      observacao: '',
      pagamento: '',
      produtos: '',
      metragem: ''
    },
  });

  const [dataDiaristas, setDataDiarista] = useState(colaboradores);
  const [rangeValue, setRangeValue] = useState(0);
  const [findMaker, setFindMaker] = useState(false);
  const [findByCep, setFindByCep] = useState(false);
  const [servicos, setServicos] = useState([]);
  const [timeToView, setTimeToView] = useState(null);
  const [stateOptions, setOptions] = useState({
    sortBy: 'distance',
    orderBy: 'asc',
    filterBy: '',
    searchBy: '',
    changed: false
  });

  const handleToggle = (name, flag) => {
    setHandleToogles((toogles) => ({...toogles, [name]: flag}));
  }

  const openPropose = () => {

    handleToggle('modalPropose', true);

    let form = {...state.form, ...props?.user}

    setState({
      ...state,
      form
    });

    setDataDiarista(colaboradores);
    var myMarks = colaboradores.map(item => item.valor).filter((x, i, a) => a.indexOf(x) == i).map(item => {
      return {
        value: item,
        label: Service.formatCurrency(item)
      }
    }).sort((a, b) => a.value - b.value)

    if(myMarks && myMarks.length > 0){
      setMarks(myMarks);
      setRangeValue(myMarks[myMarks.length -1].value);
    }

    handleToggle('modalPropose', true);
  }

  const closePropose = (event) => {
    handleToggle('modalPropose', false);
    handleToggle('step', 'information');
  }

  const creditos = () => {
    closePropose();
    PubSub.publish('openFormPayment', {});
  }

  const getPeople = async (value) => {

      var data = {
        maxDistance: value || state.form.maxDistance || 1,
        type: 'CONTRATADO',
        loc: state.form.loc        
      }

      var params = new URLSearchParams(data).toString();

      var people = await Service.get(`${Service.api}/colaboradores/getPeople?${params}`)

      handleToggle('people', people)
  }

  const handleSubmitPropose = async (event) => {

    event.preventDefault();

    if(props?.user?.type === 'CONTRATADO'){
      swal({content: <div><p className="text-center">{props?.user?.nome}, você está cadastrada como DIARISTA e essa funcionalidade é apenas para CONTRATANTES.</p><h3 className="barlow fw-bold">Os contratantes fazem o disparo e você recebe o aviso no seu e-mail!</h3></div>, icon: 'info'}).then(() => {
        closePropose();
      });
      return false;
    }

    setTimeout(() => {
      Service.scrollIntoView('informationHouse');
    }, 1000);


    /* no primeiro submit, repassa pro secondStep */
    if(toogles.step === 'information'){
      if(props?.disparos?.length === 1){


        swal({
          title: 'ATENÇÃO!',
          content: <div><p className="text-center">É indicado que você selecione mais de 1 diarista para não repetir a publicação da Oferta.</p><p className="fw-bold">Deseja realmente enviar sua oferta apenas para essa diarista?</p></div>,
          buttons: {
            cancel: "Cancelar",
            confirm: "Confirmar"
          },
        }).then(resp => {
          if(resp){
            handleToggle('step', 'primeiro');
          }
        });
      }else{
        handleToggle('step', 'primeiro');
      }
    }

    if(toogles.step === 'primeiro'){


      handleToggle('step', 'segundo');
    }

    if(toogles.step === 'segundo'){


        let fones = state.form.telefones;

        if(!fones){
          alert('Por favor, digite seus telefones, caso contrário a pessoa não conseguirá entrar em contato com você!');
          return false;
        }


      handleToggle('step', 'terceiro');

      var media = await Service.get(`${Service.api}/publishs/timeToView`)


      const slider = document.getElementById('maxDistanceRange');

      if(slider){
        slider.addEventListener('change', (event) => {
          getPeople(event.target.value)
        });
      }

      getPeople()

      // var data = {
      //   maxDistance: state.form.maxDistance || 1,
      //   type: 'CONTRATADO',
      //   loc: state.form.loc        
      // }

      // var params = new URLSearchParams(data).toString();

      // var people = await Service.get(`${Service.api}/colaboradores/getPeople?${params}`)

      // handleToggle('people', people)


      setTimeToView(media)
    }

    if(toogles.step === 'terceiro'){


      handleToggle('step', 'quarto');

          // let lat = state.form.loc[1];
          // let lng = state.form.loc[0];

          // Service.getCompaniesbyGoogle().then(google => {
          //   var pyrmont = {lat, lng};
          //   var map = new google.Map(document.getElementById('map'), {
          //     center: pyrmont,
          //     zoom: 17
          //   });
          // })
    }

    if(toogles.step === 'quarto'){



        let valid = true;
        let fones = state.form.telefones;


        if(!fones){
          alert('Por favor, digite seus telefones, caso contrário a pessoa não conseguirá entrar em contato com você!');
          return false;
        }

        fones.forEach(tel => {
          if(!tel.operadora || tel.operadora === "" || !tel.telefone || tel.telefone === ""){
            valid = false;
          }
        });

        if(!valid  || !fones){
          alert('Por favor, digite seus telefones, caso contrário a pessoa não conseguirá entrar em contato com você!');
          return false;
        }

      let dataColaboradores = dataDiaristas.map(item => {
            return item._id
        });

      if(dataColaboradores.length > 40){
        alert('O máximo de profissionais selecionados é 40\n\nVocê selecionou um número maior que o permitido para fazer o disparo\n\nVolte e remova algumas pessoas!');
        return false;
      }


      logEvent('PUSH', `SUBMETENDO PROPOSTA`);

      if(!props.isAuthenticated){
        PubSub.publish('openModal');
        return false
      }

      PubSub.publish('backdrop', true);
      handleToggle('modalPropose', false);


        Service.savePropose({
          user: state.form,
          dataColaboradores
        }).then(resp => {

          if(!resp.sucess){

            if(resp.code === 'OFERTAS-ABERTAS'){
              swal({
                icon: 'info',
                title: 'Oferta não publicada!',
                content: <div><p className="text-center">Você tem ofertas abertas para o mesmo endereço.</p><p className="fw-bold">Sua oferta não foi publicada!</p></div>
              })
              return false;
            }

            if(resp.code === 'LIMIT-OFERTAS'){

              swal({
                title: 'Publicar Oferta!',
                content: <div><p className="text-center">Você atingiu o limite de disparos, adquira créditos para realizar o disparo.</p><p className="fw-bold">Gostaria de usar a funcionalidade "Publicar Oferta"?</p></div>,
                buttons: {
                  cancel: "Não, Obrigado!",
                  confirm: "Bora lá!"
                },
              }).then(resp => {
                if(resp){
                  PubSub.publish('openFormPayment', props.user);
                }
              });
            }
            return false;
          }

          handleToggle('modalPropose', true);
          handleToggle('step', 'quinto');


        }).catch(e => {
          alert(e)
        }).finally(e => {

            PubSub.publish('backdrop', false);
            //props.publish([]);
            //cancelarOferta();

        });
    }
  }

  const handleCheckInformacoes = (event) => {
    let value = event.target.value;

    let informacoes_especiais = state.form.informacoes_especiais;

    if (informacoes_especiais.includes(value)) {
      let index = informacoes_especiais.indexOf(value);
      informacoes_especiais.splice(index, 1);
    } else {
      informacoes_especiais.push(value);
    }

    setState({
      ...state,
      form: {
        ...state.form,
        informacoes_especiais: informacoes_especiais
      }
    });
  }

  const handleCheck = (event) => {
    let value = event.target.value;

    let servicos_especiais = state.form.servicos_especiais;

    if (servicos_especiais.includes(value)) {
      let index = servicos_especiais.indexOf(value);
      servicos_especiais.splice(index, 1);
    } else {
      servicos_especiais.push(value);
    }

    setState({
      ...state,
      form: {
        ...state.form,
        servicos_especiais: servicos_especiais
      }
    });
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...state.form,
          [name]: value
        }
      }
    });
  }

  /* retorna a latitude e longitude via localizacao propria do usuario e depois via google */
  const getMarker = async () => {

    setFindMaker(true);
    let form = { ...state.form, endereco: '', numero: '', uf: '', cidade: '', bairro: '', loc: [], cep: ''};
    setState({
      ...state,
      form
    })

    const address = await Service.getMarker();

    let user = Service.parseMarkerAddress(address[0])

    showAddress(user);
    setFindMaker(false);
  }

  /* retorna a latitude e longitude via cep google */
  const getCepbyGoogle = (cep) => {

    cep = cep.replace(/\D/g, "");
    if (!cep) {      
      return false;
    }

    if(cep.length !== 8){
      swal('Ixi!', 'Digite um cep válido', 'error');
      return false;
    }

    setFindByCep(true);

    let form = { ...state.form, endereco: '', numero: '', uf: '', cidade: '', bairro: '', loc: []};

    setState({
      ...state,
      form
    })

    Service.googleMaps(cep).then(resp => {

      if(!resp){
        swal('', 'Parece que esse CEP não foi encontrado na base do Google!', 'info');
        return false;
      }

      showAddress(resp);
    }).catch(e => {
      swal('', 'Ocorreu um erro em sua requisição de cep', 'error');
    }).finally(() => {
      setFindByCep(false)
    });
  }

  //formata os dados da geolocation
  const showAddress = (resp) => {
    if (!resp) {
      swal('', "Ixe! Cep não encontrado", 'error');
      return false;
    }

    //let user = Service.parseMarkerAddress(resp);

    let form = { ...state.form, ...resp };
    setState({
      ...state,
      form: form
    });
  }

  const removeDiarista = (item) => {
    setDataDiarista(dataDiaristas.filter(model => model._id !== item._id));
  }

  const filterByRange = (event) => {
    setRangeValue(event.target.value);
    setDataDiarista(colaboradores.filter(model => model.valor <= event.target.value)); 
  }

  const cancelarOferta = () => {
    props.publish([]);
    closePropose();
  }

  useEffect(() => {

    if(props.isAuthenticated && props.user){

      let form = {...state.form, _id: props.user._id}

      if(!state.form.loc || state.form.loc.length === 0) {

        var user = props.user;

        form = {...form, 
          endereco: user.endereco,
          cep: user.cep,
          numero: user.numero,
          uf: user.uf,
          cidade: user.cidade,
          telefones: user.telefones,
          bairro: user.bairro,
          loc: user.loc        
        }
      }

      setState({
        ...state,
        form
      });

      setDataDiarista(colaboradores);
      var myMarks = colaboradores?.map(item => item.valor).filter((x, i, a) => a.indexOf(x) == i).map(item => {
        return {
          value: item,
          label: Service.formatCurrency(item)
        }
      }).sort((a, b) => a.value - b.value);

      if(myMarks?.length){
        setMarks(myMarks);
        setRangeValue(myMarks[myMarks.length -1].value);
      }
    }

    return () => {}
    
  }, [props?.isAuthenticated, props?.user, props?.user?.telefones])

  const servicosEspeciais = [
    {
      value: 'Cozinhar'
    },
    {
      value: 'Lavar roupa'
    },
    {
      value: 'Passar roupa'
    },
    {
      value: 'Limpar quintal',
    },
    {
      value: 'Limpar vidros e janelas',
    },
    {
      value: 'Limpar sujeira de animais'
    },
    {
      value: 'Limpar tapetes e estofados'
    },
    {
      value: 'Limpar interior geladeira',
    },
    {
      value: 'Limpar interior microondas',
    },
    {
      value: 'Limpar interior de caixas e gavetas'
    },
    {
      value: 'Limpar interior de armários de cozinha'
    }
  ];

  const informacoesAdicionais = [
    {
      value: 'Tem cães',
    },{
      value: 'Tem gatos',
    },{
      value: 'Tem bebês',
    },{
      value: 'Tem pessoas idosas'
    },{
      value: 'Tem pessoas alérgicas',
    },{
      value: 'Tem pessoas trabalhando (home-office)'
    },{
      value: 'Tem pessoas com necessidades especiais'
    }
  ];

  var keywordList = ['apagar', 'enviar novamente'];

  const sugerirDisparo = true;

  const [recording, setRecording] = useState(false)
  const [rec, setRecognition] = useState(null)

  useEffect(() => {

    if(transcript?.length <= 500){

      setState({
        ...state,
        form: {
          ...state.form,
          'observacao': transcript
        }
      });

      var textarea = document.getElementById('observacao');
      if(textarea){
        textarea.scrollTop = textarea.scrollHeight;
      }
    }

    return () => {}

  }, [transcript])

  const speech = async (e) => {

    if(listening){
      SpeechRecognition.stopListening()
      // rec.stop();
      // setRecording(false);
      return;
    }else{
      SpeechRecognition.startListening({
        language: 'pt-BR',
        continuous: true
      })
      return;
    }

    setRecording(true);

    // Cria um novo objeto SpeechRecognition
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    var recognition = new window.SpeechRecognition()
    setRecognition(recognition);

    // Define as configurações da API
    recognition.continuous = true;
    recognition.lang = 'pt-BR';
    recognition.interimResults = true;
    recognition.maxAlternatives = 5;

    // Adiciona um evento que é executado sempre que a API reconhece a fala do usuário
    recognition.onresult = function(event) {

      console.log('recognition.onresult', event);

      var transcript = state.form.observacao;

      if (typeof(event.results) == 'undefined') {
        recognition.onend = null;
        recognition.stop();
        return;
      }

      for (var i = 0; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {

          if(event.resultIndex === 0){
            transcript += event.results[i][0].transcript;
          }else{
            const last = event.results.length - 1;
            transcript = event.results[last][0].transcript;
          }

        } else {
          transcript += event.results[i][0].transcript;
        }
      }

      //Object.entries(temp1).map(item => item[1][0].transcript).join()

      // for (let i = event.resultIndex; i < event.results.length; i++) {
      //   transcript += event.results[i][0].transcript;
      // }

      // const last = event.results.length - 1;
      // const transcript = event.results[last][0].transcript;
      //output.textContent = transcript;


      if(transcript.length > 500){
        return false;
      }

      setState({
        ...state,
        form: {
          ...state.form,
          'observacao': transcript
        }
      });

      var textarea = document.getElementById('observacao');
      textarea.scrollTop = textarea.scrollHeight;
    }

    recognition.onsoundend = function(event) {
      //console.log('recognition.onsoundend', event);
      recognition.stop();
      setRecording(false);
    }

    recognition.onaudioend = function(event) {
      //console.log('recognition.onaudioend', event);
      recognition.stop();
      setRecording(false);
    }

    recognition.onspeechend = function() {
      //console.log('recognition.onspeechend', event);
      recognition.stop();
      setRecording(false);
    }

    recognition.start();
  }

  const [animated, setAnimated] = useState(false)

  useEffect(() => {

    setAnimated(true)

    setTimeout(() => {
      setAnimated(false);
    }, 1000)

    return () => {}
  }, [props?.disparos])


  const onLogin = () => {
    PubSub.publish('openModal');
    return false    
  }


  const getMedia = (data) => {

    var valor = 0;
    if(data.length) {
      valor = data?.map(item => item?.valor).reduce((a,b) => a+b, 0) / data?.length
    }

    return Service.formatCurrency(valor)
  }

  const comparar = () => {
    handleToggle('drawerComparacao', true)
  }

  const handleCloseDrawer = () => {
    handleToggle('drawerComparacao', false)
  }

  return (
      <UserContext.Provider value={{ state, setState }}>
        {(!props?.user?.type || props?.user?.type !== 'CONTRATADO') && 
          <div className="position-fixed start-0 end-0 bottom-0 px-4 py-2 gap-2 d-flex">
            {props?.disparos?.length > 0 && <button onClick={comparar} type="button" className="btn btn-lg btn-danger w-100  rounded-pill fw-bold text-uppercase text-nowrap">comparar</button>}
            <button type="button" className={`${animated ? 'animated-button': ''} w-100 btn btn-primary btn-lg rounded-pill`} onClick={openPropose}>
                <span className="fw-bold text-uppercase text-nowrap">Publicar Oferta!</span>
                <div className="d-flex align-items-center justify-content-center">
                <small className="d-block fs-6">
                {props?.disparos?.length > 0 ? props?.disparos?.length === 1 ? '1 diarista': `${props?.disparos?.length} diaristas` : 'Envie sua oferta para diaristas!'}
                </small>
                </div>
            </button>
          </div>
        }
        {toogles.modalPropose &&
          <Dialog className="openPropose" open={toogles.modalPropose} fullScreen={fullScreen}>
            <form onSubmit={handleSubmitPropose} name="form" className="d-flex flex-column h-100 overflow-hidden">
              <DialogTitle>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-bold text-uppercase barlow">Quero contratar diaristas</div>
                  <IconButton aria-label="close" onClick={closePropose}>
                    <CloseIcon titleAccess="fechar modal" />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent dividers={true} className="p-0">
                  <div id="informationHouse" className="p-4">
                    
                      <Slide direction="left" in={toogles.step === 'information'} mountOnEnter unmountOnExit>
                        <div>
                          <p className="text-center"><strong className="barlow text-uppercase">Preciso de uma diarista de forma ultra rápida!</strong></p>
                          <ol>
                            <li className="mb-3">O disparo serve para informar as <strong>diaristas selecionadas</strong> que você tem uma oportunidade de trabalho para elas.</li>
                            <li className="mb-3">Todas as <strong>diaristas selecionadas</strong> são notificadas por e-mail ou via E-mail, WhatsApp, Push Notification e recebem um link para saber mais detalhes da sua oferta.</li>
                            <li className="mb-3">No final, as <strong>diaristas selecionadas</strong> e <strong>outras diaristas ativas na plataforma</strong> que talvez estejam em mais condições de lhe atender, entrarão em contato com você através dos seus telefones informados na publicação.</li>
                          </ol>
                          <p className="text-center">Você pode <strong>pausar o anúncio da sua oferta</strong> a qualquer momento acessando a sua área de contratações e indicar qual foi a pessoa contratada.</p>
                          {dataDiaristas && dataDiaristas.length === 1 && <div className="alert alert-warning text-center">
                            <p className="m-0">Procure selecionar mais de 1 diarista!</p>
                          </div>}
                        </div>
                      </Slide>

                      <Slide direction="left" in={toogles.step === 'segundo'} mountOnEnter unmountOnExit>
                          <div>
                            <DialogContentText>
                              Preencha os dados que estão faltando em seu cadastro:
                            </DialogContentText>
                            {!props.isAuthenticated && <><button type="button" onClick={onLogin} className="btn btn-primary rounded-pill px-4 d-flex m-auto">já tenho cadastro</button><hr /></>}

                            <Telefones className="telefones" telefones={state.form.telefones} max={3} />

                            <hr />
                            {/* dados da distancia */}
                            <fieldset className="">
                              <legend className="barlow fs-6 fw-bold">Qual é a distância máxima que você quer encontrar uma profissional diarista?</legend>
                              <div className="row">                    
                                <div className="col">
                                  <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="maxDistance">Selecione a distância:</label>
                                     <select
                                        name="maxDistance"
                                        id="maxDistance"
                                        className="form-control form-select"
                                        required={true}
                                        value={state.form.maxDistance}
                                        onChange={handleChange}
                                      >
                                        <option value="">Selecione</option>
                                        <optgroup label="Mais próximo de mim">
                                          <option value="1">1km</option>
                                          <option value="2">2km</option>
                                          <option value="3">3km</option>
                                          <option value="4">4km</option>
                                          <option value="5">5km</option>
                                        </optgroup>
                                        <optgroup label="Um pouquinho mais longe">
                                          <option value="10">10km</option>
                                          <option value="20">20km</option>
                                          <option value="30">30km</option>
                                          <option value="40">40km</option>
                                          <option value="50">50km no máximo</option>
                                        </optgroup>
                                      </select>
                                  </div>
                                </div>
                              </div>
                            </fieldset>


                            <hr />
                            {/* dados do endereco */}
                            <fieldset className="">
                              <legend className="barlow fs-6 fw-bold">Para qual endereço será requisito o serviço?</legend>
                              <div className="row">                    
                                <div className="col-md-4">
                                  <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="cep">Cep:</label>
                                    <div className="input-group">
                                      <InputMask
                                        type="tel"
                                        id="cep"
                                        mask="99999-999"
                                        placeholder="18800-000"
                                        name="cep"
                                        required={true}
                                        className="form-control"
                                        value={state.form.cep}
                                        onBlur={() =>
                                          getCepbyGoogle(state.form.cep)
                                        }
                                        onChange={handleChange}
                                      />
                                        <button
                                          type="button"
                                          onClick={() =>
                                            getCepbyGoogle(state.form.cep)
                                          }
                                          className="btn btn-info"
                                          type="button"
                                          title="Pesquisar Cep"
                                        >
                                          <span hidden={findByCep}>Ok</span>
                                          <span hidden={!findByCep}>...</span>
                                        </button>
                                    </div>
                                    <small className="form-text text-muted">
                                      <span hidden={!findMaker}>
                                        pesquisando...
                                      </span>
                                      <button
                                        type="button"
                                        hidden={findMaker}
                                        onClick={() => getMarker(event)}
                                        className="btn btn-link"
                                        title="Usar posição atual"
                                      >
                                        Não sei meu cep
                                      </button>
                                    </small>
                                  </div>
                                </div>
                                <div className="col-md-6" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group mb-3">
                                          <label className="form-label" htmlFor="endereco">Endereço</label>
                                          <input
                                            type="text"
                                            placeholder="Endereço"
                                            name="endereco"
                                            required={true}
                                            id="endereco"
                                            className="form-control"
                                            value={state.form.endereco}
                                            onChange={handleChange}
                                          />
                                  </div>
                                </div>
                                <div className="col-md-2" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group mb-3">
                                          <label className="form-label" htmlFor="numero">Número</label>
                                          <input
                                            type="text"
                                            placeholder="Nº"
                                            id="numero"
                                            name="numero"
                                            required={true}
                                            className="form-control"
                                            value={state.form.numero}
                                            onChange={handleChange}
                                          />
                                  </div>
                                </div>

                                <div className="col-12" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group mb-3">
                                          <label className="form-label" htmlFor="referencia">Ponto de referência</label>
                                          <input
                                            type="text"
                                            placeholder="Referência"
                                            name="referencia"
                                            id="referencia"
                                            className="form-control"
                                            value={state.form.referencia}
                                            onChange={handleChange}
                                          />
                                  </div>
                                </div>





                                <div className="col-sm-4" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="uf">Estado</label>
                                    <select
                                      name="uf"
                                      id="uf"
                                      className="form-control form-select"
                                      required={true}
                                      disabled={true}
                                      value={state.form.uf}
                                      onChange={handleChange}
                                    >
                                      <option value="">Selecione</option>
                                      <optgroup label="Uf">
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraiba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantis</option>
                                      </optgroup>
                                    </select>
                                    <small className="form-text">
                                      Obrigatório
                                    </small>
                                  </div>
                                </div>
                                <div className="col-sm-4" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="cidade">
                                      Cidade
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Cidade"
                                      id="cidade"
                                      disabled={true}
                                      name="cidade"
                                      className="form-control"
                                      required={true}
                                      value={state.form.cidade}
                                      onChange={handleChange}
                                    />
                                    <small className="form-text">
                                      Obrigatório
                                    </small>
                                  </div>
                                </div>
                                <div className="col-sm-4" hidden={!state?.form?.loc?.length}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="bairro">
                                      Bairro
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Bairro"
                                      name="bairro"
                                      id="bairro"
                                      className="form-control"
                                      required={true}
                                      value={state.form.bairro}
                                      onChange={handleChange}
                                    />
                                    <small className="form-text">
                                      Obrigatório
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </fieldset>

                          </div>
                      </Slide>

                      <Slide direction="left" in={toogles.step === 'primeiro'} mountOnEnter unmountOnExit>
                        <fieldset className="row">


                          <div className="col-12">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="tipoServico">Qual tipo da limpeza?</label>
                              <select autoFocus required={true} className="form-control form-select" value={state.form.tipoServico} id="tipoServico" name="tipoServico" onChange={(e) => { handleChange(e); document.getElementById('bedrooms').focus()}}>
                                <option></option>
                                <optgroup label="TIPO DA LIMPEZA">
                                  <option value="LIMPEZA-COMERCIAL">LIMPEZA COMERCIAL</option>
                                  <option value="LIMPEZA-PADRAO">LIMPEZA PADRÃO</option>
                                  <option value="LIMPEZA-PESADA">LIMPEZA PESADA</option>
                                  <option value="LIMPEZA-PRE-MUDANCA">LIMPEZA PRÉ MUDANÇA</option>
                                  <option value="LIMPEZA-POS-OBRA">LIMPEZA PÓS-OBRA</option>
                                  <option value="LIMPEZA-STUDIO">LIMPEZA DE STUDIO</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>

                          
                          <legend className="fs-6 barlow fw-bold">Qual a quantidade de cômodos?</legend>
                          <div className="col-6 col-md-3">
                            <div className="form-group mb-3">
                              <label className="form-label barlow" htmlFor="bedrooms">Quartos</label>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <Icon>single_bed</Icon>
                                </span>
                                <input value={state.form.bedrooms} onChange={(e) => { handleChange(e); document.getElementById('bathrooms').focus()}} type="number" className="form-control" min={1} max={9} id="bedrooms" name="bedrooms" />
                              </div>
                            </div>
                          </div>

                          <div className="col-6 col-md-3">
                            <div className="form-group mb-3">
                              <label className="form-label barlow" htmlFor="bathrooms">Banheiros</label>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <Icon>bathtub</Icon>
                                </span>
                                <input value={state.form.bathrooms} onChange={(e) => { handleChange(e); document.getElementById('rooms').focus()}} type="number" className="form-control" min={1} max={9} id="bathrooms" name="bathrooms" />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-md-3">
                            <div className="form-group mb-3">
                              <label className="form-label barlow" htmlFor="rooms">Salas</label>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <Icon>chair</Icon>
                                </span>
                                <input value={state.form.rooms} onChange={(e) => { handleChange(e); document.getElementById('kitchen').focus()}} type="number" className="form-control" min={1} max={9} id="rooms" name="rooms" />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-md-3">
                            <div className="form-group mb-3">
                              <label className="form-label barlow" htmlFor="kitchen">Cozinhas</label>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <Icon>kitchen</Icon>
                                </span>
                                <input value={state.form.kitchen} onChange={(e) => { handleChange(e); document.getElementById('date').focus()}} type="number" className="form-control" min={1} max={9} id="kitchen" name="kitchen" />
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="date">Qual dia?</label>
                              <input required={true} type="date" min={state.form.minDate} value={state.form.date} onChange={handleChange} id="date" name="date" className="form-control" />
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="horario">Qual horário?</label>
                              <select required={true} className="form-control form-select" value={state.form.horario} id="horario" name="horario" onChange={(e) => { handleChange(e); document.getElementById('pagamento').focus()}}>
                                <option></option>
                                <optgroup label="Manhã">
                                  <option value="MANHA">MANHÃ</option>
                                </optgroup>
                                <optgroup label="Tarde">
                                  <option value="TARDE">TARDE</option>
                                </optgroup>
                                <optgroup label="Dia Todo">
                                  <option value="DIATODO">DIA TODO</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="pagamento">Pagamento</label>
                              <select required={true} className="form-control form-select" value={state?.form?.pagamento} id="pagamento" name="pagamento" onChange={(e) => { handleChange(e); document.getElementById('frequencia').focus()}}>
                                <option value=""></option>
                                <option value="PIX">PIX</option>
                                <option value="DINHEIRO">DINHEIRO</option>
                                <option value="CARTAO">CARTÃO</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="frequencia">Freqüência</label>
                              <select required={true} className="form-control form-select" value={state?.form?.frequencia} id="frequencia" name="frequencia" onChange={(e) => { handleChange(e); document.getElementById('metragem').focus()}}>
                                <option value=""></option>
                                <option value="SEMANAL">SEMANAL</option>
                                <option value="QUINZENAL">QUINZENAL</option>
                                <option value="MENSAL">MENSAL</option>
                                <option value="UNICA-DIARIA">ÚNICA DIÁRIA</option>
                                <option value="FIXA">FIXA</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="metragem">Qual a metragem do imóvel?</label>
                              <select className="form-control form-select" value={state?.form?.metragem} id="metragem" name="metragem" onChange={(e) => { handleChange(e); document.getElementById('produtos').focus()}}>
                                <option value=""></option>
                                <option value="-50">MENOS de 50m2</option>
                                <option value="51-80">51 a 80 m2</option>
                                <option value="81-100">81 a 100 m2</option>
                                <option value="101-130">101 a 130 m2</option>
                                <option value="131-200">131 a 200 m2</option>
                                <option value="200+">MAIS de 200m2</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="produtos">Material para realizar o serviço?</label>
                              <select required={true} className="form-control form-select" value={state?.form?.produtos} id="produtos" name="produtos" onChange={(e) => { handleChange(e); document.getElementById('conducaoAlimentacao').focus()}}>
                                <option value=""></option>
                                <option value="POSSUO-MATERIAL">POSSUO O MATERIAL</option>
                                <option value="PRECISA-TRAZER">PRECISA TRAZER MATERIAL</option>
                                <option value="A-COMBINAR">A COMBINAR</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group mb-3">
                              <label className="form-label fw-bold barlow" htmlFor="conducaoAlimentacao">Alimentação e Condução?</label>
                              <select required={true} className="form-control form-select" value={state?.form?.conducaoAlimentacao} id="conducaoAlimentacao" name="conducaoAlimentacao" onChange={handleChange}>
                                <option value=""></option>
                                <option value="INCLUIR-NO-VALOR-DO-SERVICO">INCLUIR NO VALOR DO SERVIÇO</option>
                                <option value="POR-CONTA-CONTRATANTE">POR CONTA DO CONTRATANTE</option>
                                <option value="POR-CONTA-COLABORADOR">POR CONTA DA DIARISTA</option>
                                <option value="A-COMBINAR">A COMBINAR</option>
                              </select>
                            </div>
                          </div>



                          

                          <fieldset className="form-group mb-3">
                            <legend>Serviços adicionais?</legend>
                            <label className="form-label fw-bold barlow">Selecione as prioridades que você gostaria que fosse realizada.</label>
                            <small className="d-block mb-3">Tenha consciência que quanto mais itens você selecionar, a profissional diarista <strong>não dará conta de fazer tudo em um dia</strong> e desta forma também haverá alteração de valores na negociação dos serviços adicionais!</small>
                            {
                              servicosEspeciais.map((item, index) => {
                                return (
                                  <div className="form-check" key={index}>
                                    <input className="form-check-input" onChange={handleCheck} value={item.value.toUpperCase()} type="checkbox" name="servicos_especiais" id={`especiais_${index}`} />
                                    <label className="form-check-label" htmlFor={`especiais_${index}`}>
                                      {item.value.toUpperCase()}
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </fieldset>

                          <div className="form-group mb-3">
                            <label className="form-label fw-bold barlow">Informações adicionais!</label>
                            {
                              informacoesAdicionais.map((item, index) => {
                                return (
                                  <div className="form-check" key={index}>
                                    <input className="form-check-input" onChange={handleCheck} value={item.value.toUpperCase()} type="checkbox" name="informacoes_adicionais" id={`adicionais_${index}`} />
                                    <label className="form-check-label" htmlFor={`adicionais_${index}`}>
                                      {item.value.toUpperCase()}
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>

                          <div className="alert alert-info">
                            <strong>Se coloque no lugar da profissional antes de fazer sua oferta!</strong>
                            <ul className="m-0">
                              <li>Não ofereça preços abusivos;</li>
                              <li>Não ofereça ofertas ofensivas;</li>
                              <li>Não ofereça cestas básicas;</li>
                              <li>Não ofereça para profissional dormir no local;</li>
                              <li>Não ofereça ofertas naturalistas (Ex: ficar sem roupa);</li>
                              <li>Não ofereça proposta de nudez, em nenhum contexto;</li>
                              <li>Não solicite fotos do corpo, em nenhum contexto;</li>
                            </ul>
                          </div>

                          <div className="form-group mb-3">
                            <label className="form-label fw-bold barlow" htmlFor="observacao">Observação:</label>
                            <textarea onChange={handleChange} maxLength={500} id="observacao" name="observacao" value={state?.form?.observacao} className="form-control" rows={3} />
                            <small>máximo 500 caracteres - ({state?.form?.observacao.length}) caracteres</small>
                            {browserSupportsSpeechRecognition && 
                                <button type="button" onClick={speech} className={`btn w-100 ${listening ? 'btn-danger' : 'btn-info'} rounded-pill`}><MicIcon /> {listening ? 'estou escutando, se quiser pode pausar' : 'enviar áudio'}</button>
                            }
                          </div>

                          <div className="mb-3">
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" id="confirmation" required={true} />
                              <label className="form-check-label" htmlFor="confirmation">
                                Estou ciente que a plataforma é séria e respeitável... Gracinhas, brincadeiras de mal gosto, propostas indecentes, assédio moral ou sexual não são aceitos na plataforma, por isso estou ciente e aceito os termos e condições. Tais situações estarei sujeito a processos.
                              </label>
                            </div>
                          </div>


                          <div className="mb-3">
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" id="confirmation2" required={true} />
                              <label className="form-check-label" htmlFor="confirmation2">
                                Também estou ciente que a Odete não é uma agência e não atua como uma intermediadora, a Odete é apenas um classificados, gerando oportunidades para profissionais diaristas e ajudando os contratantes a encontrarem uma profissional. Toda negociação e por ventura a contratação dos serviços de qualquer profissional que esteja cadastrada na Odete é de minha responsabilidade. A Odete não tem taxa de intermediação e não tem nenhum vínculo empregatício com as pessoas cadastradas na plataforma.
                              </label>
                            </div>
                          </div>


                        </fieldset>
                      </Slide>

                      <Slide direction="left" in={toogles.step === 'terceiro'} mountOnEnter unmountOnExit>
                        <div>
                        {(dataDiaristas?.length > 0) && <p className="text-center">Sua proposta será enviada para {dataDiaristas?.length} {dataDiaristas?.length === 1 ? 'pessoa' : 'pessoas'}.</p>}
                        <p className="text-center">As pessoas irão entrar em contato com você através do seu <strong>telefone validado</strong>.</p>
                        <p className="text-center m-0">Em <strong>{state.form.maxDistance}km</strong> tem aproxidamente <strong>{toogles?.people?.length}</strong> profissionais diaristas próxima de você.</p>
                        <p className="text-center">somente as 40 mais próximas de você receberão sua oferta</p>

                        <div className="form-group mb-3">
                          <label className="form-label w-100 text-center" htmlFor="maxDistance">Mudar a distância:</label>
                          <input onChange={handleChange} value={state.form.maxDistance} type="range" min="1" max="50" className="w-100" id="maxDistanceRange" name="maxDistance" list="maxDistanceList" />
                          <datalist id="maxDistanceList">
                            <option value="1" label="1"></option>
                            <option value="2" label="2"></option>
                            <option value="3" label="3"></option>
                            <option value="4" label="4"></option>
                            <option value="5" label="5"></option>
                            <option value="10" label="10"></option>
                            <option value="20" label="20"></option>
                            <option value="30" label="30"></option>
                            <option value="40" label="40"></option>
                            <option value="50" label="50"></option>
                          </datalist>
                        </div>

                        <div className="mb-4">
                          <AvatarGroup max={4}>
                            {
                              toogles?.people && toogles?.people?.map(item => {
                                return (<Image key={item?._id} src={Service.parseURLImage(item)} alt={item?.nome} />)
                              })
                            }
                          </AvatarGroup>
                        </div>



                        <p className="text-center">A média do valor que essas profissionais estão cobrando é {getMedia(toogles?.people)} reais</p>
                        <p className="text-center">OBS: Essa é apenas uma MÉDIA, quem dá o valor final é a própria profissional que você for negociar, evite colocar preço no serviço das pessoas.</p>
                        <p className="text-center fw-bold barlow">O tempo média das profissionais diaristas em ver as ofertas está em<br /> <span className="display-6 fw-bold">{timeToView?.mediaRepostaPrimeiro}</span></p>
                        <p className="text-center">Se você tem urgência, adquira créditos para ter acesso aos telefones de profissionais diaristas e negociar diretamente sem esperar pelo retorno.</p>
                        <button className="mb-3 btn btn-primary rounded-pill m-auto text-center d-flex px-4" type="button" onClick={creditos}>Prefiro créditos</button>

                        <div className="alert alert-info text-center">Caso você consiga ter sucesso em sua contratação, não esqueça de avaliar ou recomendar a pessoa contratada.</div>
                        {
                          marks.length > 0 && 
                          <div className="form-group text-center mb-3">
                            <label htmlFor="form-range" className="form-label">Média dos valores entre ({Service.formatCurrency(marks[0]?.value)} e {Service.formatCurrency(marks[marks.length-1]?.value)}) - <strong>{Service.formatCurrency(rangeValue)}</strong></label>
                            <input onChange={filterByRange} id="form-range" defaultValue={marks[marks.length-1]?.value} min={marks[0]?.value} max={marks[marks.length-1]?.value} type="range" className="form-range" />
                          </div>
                        }

                        <div className="d-flex flex-wrap justify-content-center">
                          {
                            dataDiaristas && dataDiaristas?.map(item => (
                              <div className="items text-center position-relative" key={item._id}>
                                <button type="button" onClick={() => removeDiarista(item)} className="btn p-0 m-0 position-absolute" style={{
                                top: -11,
                                right: 0,
                                background: 'white',
                                borderRadius: '50%'                         
                                }}>
                                  <HighlightOffIcon color="secondary" />
                                </button>
                                  <Image
                                    src={Service.parseURLImage(item)}
                                    alt={item.nome}
                                    className="rounded-circle d-block img-thumbnail m-auto image-perfil"
                                    width="60"
                                    height="60"
                                  />


                              </div>
                            ))
                          }
                        </div>
                        </div>
                      </Slide>

                      <Slide direction="left" in={toogles.step === 'quarto'} mountOnEnter unmountOnExit>
                        <div>

                            <div>
                              <div className="text-center">
                                <LocationOnIcon fontSize="large" />
                                <p className="fw-bold lead">{state.form.endereco}, {state.form.numero} - {state.form.bairro} - {state.form.cep}</p>
                                <InfoIcon fontSize="large" className="text-warning" />
                                <p>Lembrando que toda negociação será realizada entre você e a pessoa contratada. Não esqueça de avaliar o serviço realizado e recomendar a pessoa contratada.</p>
                                <CheckCircleIcon fontSize="large" className="text-success" />
                                <p>Após <strong>FINALIZAR</strong>, sua oferta será imediatamente publicada!</p>
                                <div>
                                  <label className="form-label barlow fw-bold" htmlFor="diasEmAberto">Quantos dias sua oferta deve ficar em aberto?</label>
                                  <select required={true} className="form-select form-control-lg w-50 m-auto" name="diasEmAberto" id="diasEmAberto" value={state.form.diasEmAberto} onChange={handleChange}>
                                    <option value="">Selecione</option>
                                    <optgroup label="No mínimo">
                                      <option value="3">3 dias</option>
                                      <option value="5">5 dias</option>
                                      <option value="7">7 dias</option>
                                    </optgroup>
                                    <optgroup label="No máximo">
                                      <option value="15">15 dias</option>
                                      <option value="30">30 dias</option>
                                    </optgroup>
                                  </select>
                                  <div className="form-text mb-4">
                                    Após o término do prazo, você receberá um e-mail com a lista de todas as profissionais que visualizaram sua oferta, além de um link para confirmar se alguma delas foi contratada por você nessa oportunidade.
                                  </div>
                                  <h3 className="barlow fw-bold">Obrigado por confiar na Odete!</h3>
                                </div>
                            </div>
                          </div>
                        </div>
                      </Slide>

                      <Slide direction="left" in={toogles.step === 'quinto'} mountOnEnter unmountOnExit>
                        <div>
                            <div className="text-center">
                              <img src="/assets/images/icons/star-success.png" />
                              <p className="lead font-weight-bold">Proposta cadastrada com sucesso</p>
                            </div>

                            <div>
                              <div className="alert alert-info text-center">
                                <p className="m-0">Olá {props?.user?.nome}, sua proposta foi publicada com sucesso.</p>
                              </div>
                              <div className="alert alert-warning text-center">
                                <p className="m-0">Lembrando que toda negociação será realizada entre você e a pessoa contratada. Não esqueça de avaliar o serviço realizado e recomendar a pessoa contratada.</p>
                              </div>
                            </div>

                            <div className="text-center">
                              <h2 className="text-center h6">
                                ajude compartilhando a plataforma
                              </h2>
                              <div className="d-flex align-items-start mb-4 flex-fill justify-content-center">
                                <div>
                                  <FacebookShareButton url={url} quote={titleShare}>
                                    <FacebookIcon size={48} round />
                                  </FacebookShareButton>
                                  <div>
                                    <FacebookShareCount url={url}>
                                      {count => count}
                                    </FacebookShareCount>
                                  </div>
                                </div>
                                <WhatsappShareButton url={url} title={titleShare} separator=":: ">
                                  <small>
                                    <WhatsappIcon size={48} round />
                                  </small>
                                </WhatsappShareButton>              
                              </div>
                            </div>
                        </div>
                      </Slide>
                  </div>
              </DialogContent>            
              {
                toogles.step === 'information' && 
                  <DialogActions>
                    <button type="button" onClick={trabalhar} className="btn btn-danger btn-block btn-lg w-100 text-uppercase fw-bold barlow">
                      trabalhar!
                    </button>
                    <button type="submit" className="btn btn-primary btn-block btn-lg w-100 text-uppercase fw-bold barlow">
                      contratar!
                    </button>

                  </DialogActions>  
              }

              {
                toogles.step === 'primeiro' && 
                  <DialogActions>
                    <button type="submit" className="btn btn-primary btn-block btn-lg w-100 text-uppercase fw-bold barlow">
                      próximo
                    </button>
                  </DialogActions>  
              }

              {
                toogles.step === 'segundo' && 
                  <DialogActions>
                        <div className="flex-fill d-flex gap-2">
                          <button type="button" onClick={() => handleToggle('step', 'primeiro')} className="w-100 btn btn-outline-secondary btn-lg btn-block text-uppercase fw-bold barlow">voltar</button>
                          <button type="submit" className="w-100 btn btn-primary btn-lg btn-block text-uppercase fw-bold barlow">próximo</button>
                        </div>
                  </DialogActions> 
              }

              {
                toogles.step === 'terceiro' && 
                  <DialogActions>
                        <div className="flex-fill d-flex gap-2">
                          <button type="button" onClick={() => handleToggle('step', 'segundo')} className="w-100 btn btn-outline-secondary btn-lg btn-block text-uppercase fw-bold barlow">voltar</button>
                          <button type="submit" className="w-100 btn btn-primary btn-lg btn-block text-uppercase fw-bold barlow">próximo</button>
                        </div>
                  </DialogActions> 
              }


              {
                toogles.step === 'quarto' && 
                  <DialogActions>
                    <button type="button" onClick={() => handleToggle('step', 'terceiro')} className="btn btn-outline-secondary btn-lg w-50 text-uppercase fw-bold barlow">voltar</button>
                    <button type="submit" className="btn btn-primary btn-lg w-50 text-uppercase fw-bold barlow">Finalizar</button>
                  </DialogActions> 
              }
            </form>
          </Dialog>
        }

          <Drawer open={toogles.drawerComparacao} onClose={handleCloseDrawer} anchor="bottom">
            

            <DialogTitle>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">Comparação</div>
                <IconButton aria-label="close" onClick={handleCloseDrawer}>
                  <CloseIcon titleAccess="fechar modal" />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers={true} className="h-100 d-flex flex-column">

            <p>Essa funcionalidade não tem o objetivo de indicar e nem influenciar a sua escolha, mas sim a te ajudar a fazer uma escolha mais assertiva! A responsabilidade da negociação e eventual contratação é 100% do contratante e está nos termos de aceite da plataforma.</p>

            <table className="table">
              <thead className="sticky-top top-0">
                <tr>
                  <th></th>
                {
                  props?.disparos?.map(item =>
                    <th><img src={Service.parseURLImage(item)}  width='48' height="48" className="image-perfil rounded-circle" alt={item.nome} /></th>
                )}

                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>Nome</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.nome}</td>
                    )}
                  </tr>

                  <tr>
                    <td>Avaliação</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.rating.toFixed()}</td>
                    )}
                  </tr>


                  <tr>
                    <td>% de contratação</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.porcentagemContratacao}</td>
                    )}
                  </tr>


                  <tr>
                    <td>Pontuação</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalScore}</td>
                    )}
                  </tr>

                  <tr>
                    <td>Vídeo de Apresentação</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{(item?.videoApresentacao || item?.youTubeVideoId) && <ModalVideoApresentacao url={item.youTubeVideoId ? `https://www.youtube.com/embed/${item.youTubeVideoId}` : item?.videoApresentacao} />}</td>
                    )}
                  </tr>

                  <tr>
                    <td>Valor Aproxidamente</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{Service.formatCurrency(item.valor)}</td>
                    )}
                  </tr>



                  <tr>
                    <td>Selos</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalSelos}</td>
                    )}
                  </tr>

                 <tr>
                    <td>Recomendações</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalRecomendacoes}</td>
                    )}
                  </tr>


                <tr>
                    <td>Propostas Visualizadas</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalPropostasVisualizadas}</td>
                    )}
                  </tr>

                <tr>
                    <td>Dicas</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalDicas}</td>
                    )}
                  </tr>

                <tr>
                    <td>Total de Matchs</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{item.totalMatchs}</td>
                    )}
                  </tr>


                <tr>
                    <td>Último Login</td>
                    {
                      props?.disparos?.map(item =>
                        <td>{Service.formatDate(item.ultimoAcesso)}</td>
                    )}
                  </tr>







              </tbody>
            </table>

            </DialogContent>
          </Drawer>

      </UserContext.Provider>
  )
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user,
    disparos: state?.authentication?.disparos || []
  }
);

export default connect(mapStateToProps, actions)(Disparo);