import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js'

import Service from "../services/service";
import { logEvent } from '../utils/analytics';

import { connect } from 'react-redux';
import actions from '../redux/actions';

import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Drawer from '@material-ui/core/Drawer';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@material-ui/core/DialogContentText';

import InputMask from 'react-input-mask';
import Router from "next/router";
import swal from '@sweetalert/with-react';

const phoneButton = (props) => {

  const [submited, setSubmited] = useState(false);
  const [pedidoTelefone, setPedidoTelefone] = useState(false);
  const [noContactSend, setNoContactSend] = useState(false);
  const [telefones, setTelefones] = useState(null);
  const [model, setModel] = useState({...props.model});
  const [anchorEl, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const [falha, setFalha] = useState(null)
  const [criterioFalha, onChangeCriterio] = useState(null)

  const handleClickMenu = (tel) => {
    setAnchor(document.getElementById(`menu-${tel.telefone}`))
  }

  const toggleCotacao = (model) => {

    let colaboradores = [];
    let data = props?.disparos || [];

    if(data && data?.map(item => item._id).includes(model._id)){
      colaboradores = data.filter(item => item._id !== model._id);
      model.checkedCotacao = false;
    }else{
      colaboradores = [...data, model];
      model.checkedCotacao = true;
    }

    props.publish(colaboradores);
  }

  const getTelefone = async (model) => {

    const id = props.model._id; // id da diarista
    const isAuthenticated = props.isAuthenticated;

    logEvent('PERFIL', 'GET TELEFONE');

    /* abre a janela de modal */
    if(!isAuthenticated){
      PubSub.publish('openModal', model);
      setPedidoTelefone(true);
      return false;
    }

    /* abre a janela de confirmar telefone */
    if(isAuthenticated && props?.user && !props?.user?.userTokenVerify){
      PubSub.publish('openModalTelefone', model);
      setPedidoTelefone(true);
      return false;
    }

    if(props?.user?._id === id){
      swal('', 'Ops, você não pode pedir pra visualizar seu próprio telefone :)', 'error');
      return false;
    }

    setPedidoTelefone(true);
  }

  const actionCard = async (action, model, tel) => {

    if(action === 'ligar'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para ligar para: ${model.nome}`);
      window.open(`tel:${tel.telefone}`, '_blank');
      //location.href = `tel:${tel.telefone}`;
    }else if(action === 'enviarWhatsApp'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para enviar whatspps para: ${model.nome}`);
      //location.href = Service.renderLinkTelefone(tel, model);
      window.open(Service.renderLinkTelefone(tel, model, props.user), '_blank');
    }else if(action === 'chat') {
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para entrar no chat com : ${model.nome}`);
      Router.push('/chats');
    }else if(action === 'recordVoice'){
      let user = `${props.user.nome} - ${props.user.email}`;
      logEvent('PERFIL', `${user} - clicou para enviar um audio para : ${model.nome}`);
      let numero = prompt('Qual o número de seu telefone com DDD?');
      if(numero){
        numero = numero.split('').join(" ");
        let message = prompt('Digite a sua mensagem que sera traduzida para audio', `Oi, meu nome é ${props.user.nome}, acessei o site Odete.com.br e gostaria de saber mais sobre seu serviço de diarista. Meu número de telefone com DDD é: ${numero}`);
        let data  = await Service.tts({
          message,
          numero: tel.telefone
        }).then(resp => {
          alert('sua mensagem foi enviada com sucesso');
        });
        console.log(data)
      }else{
        alert('acao nao realizada');
      }
    }else if(action === 'notContact'){
      setFalha(model)
    }
    else{
      alert('acao nao realizada' + action);
    }
  }

  useEffect(() => {

      if(pedidoTelefone && props.isAuthenticated){

        const id = props.model._id; // id da diarista

        setSubmited(true);
        Service.postTelefone({id}).then(resp => {

          if(!resp.success){

            if(resp?.code === 'userTokenVerify'){
              PubSub.publish('openModalTelefone', props.model);
              return false;
            }

            if(resp?.code === 'pacotePremium'){
              PubSub.publish('openFormPayment', props.model);
              return false;
            }

            return false;
          }

          var confetti = document.getElementsByName(`toogle-heart-${id}`);
          confetti.forEach(item => {
            item.checked = true;
          })
          
          //document.getElementsByName(`toogle-heart-${id}`)[0].checked = true;

          setTimeout(() => {

            // if(!props?.model?.checkedCotacao){
            //   toggleCotacao(props?.model);
            // }

            confetti.forEach(item => {
              item.checked = false;
            })

            let newModel = {...props?.model, checkedCotacao: true, telefones: resp?.telefones, next: parseInt(resp?.next, 10)};
            setModel(newModel);

            setTimeout(() => {
              handleClickMenu(resp?.telefones[0]);  
            }, 500)

            
          }, 1800);

        })
        .catch(e => {
          console.log(e);
          alert('Ixi...Ocorreu um erro');
        })
        .finally(() => {
          setPedidoTelefone(false);
          setSubmited(false);
        })
      }

    return () => {} 

  }, [pedidoTelefone, props.isAuthenticated])

  const handleClose = () => {
    setFalha(null);
    onChangeCriterio(null);
    setNoContactSend(false);
    setAnchor(null);
  }

  const handleSubmit = () => {

      if(criterioFalha){
        setNoContactSend(true);

        //abre o modal com o model da diarista
        //pra gente receber o aviso pra retirar do sistema
        let user = `${props.user.nome} - ${props.user.email}`;
        logEvent('NOT-CONTACT', `${user} - nao conseguiu contato com: ${model.nome}`);

        Service.notContact(props.user, model, criterioFalha).then(resp => {


          swal({content: <div><p className="text-center">Obrigada, recebemos o seu feedback e iremos entrar em contato com essa diarista!</p><p>Não se preocupe, você receberá mais créditos por ter nos avisado sobre a falha ❤</p><h3 className="barlow fw-bold">Na Odete você pode MUITO mais!</h3></div>, icon: 'success'})


        }).finally(() => {
          handleClose();
        });
      }
  }

  const criterios = ['Telefone Inválido', 'Whatspps Inválido', 'Não trabalha mais como diarista', 'Telefone é de outra pessoa', 'Não respondeu', 'Outro motivo...']

  return (
    <>
      {
        (!telefones && !model.telefones) &&
          (<button disabled={submited || model.telefones} type="button" onClick={() => getTelefone(model)} className={`btn ${!Service.verificaPagamento(model) ? 'btn-primary' : 'btn-outline-primary'} rounded-pill ${props.className} barlow fw-bold text-uppercase w-100`} title="Visualizar Telefone">
            {submited ? "consultando" : 'VER TELEFONE'}
          </button>)
      }


      { model.telefones && <div className="list-groups mb-3 w-100">            
        {
            model.telefones && model.telefones.map(tel => (
                <React.Fragment key={tel.telefone}>
                  <div className="input-group mb-1">
                    <InputMask 
                      className="form-control py-2 rounded-pill me-1 pe-5 border-primary"
                      name="cep"
                      mask="(99) 99999-9999"
                      value={tel.telefone}
                      readOnly={true}
                      type="tel"
                    />
                    <button className="btn btn-primary rounded-pill ms-n5" id={`menu-${tel.telefone}`} aria-label="pesquisar" aria-controls={`menu-${tel.telefone}`} aria-haspopup="true" onClick={() => handleClickMenu(tel)}>
                      <MoreVertIcon fontSize="small" titleAccess="abrir opções" />
                    </button>
                    <Drawer anchor='bottom' open={Boolean(anchorEl?.id === `menu-${tel.telefone}`)} onClose={handleClose} classes={{ paperAnchorBottom: 'h-100 flex-column overflow-hidden d-flex position-relative'}}>
                      <div className="position-absolute top-0 end-0 mx-4 my-2 border z-3 rounded-pill bg-white">
                        <IconButton aria-label="close" onClick={handleClose}>
                          <CloseIcon titleAccess="fechar modal" />
                        </IconButton>
                      </div>
                      <div className="p-4 flex-fill overflow-auto position-relative">
                        <h2>Olá, {props.user.nome}!</h2>
                        <p className="text-muted">Desenvolvemos uma plataforma que facilita e acelera a conexão entre você e um profissional diarista, oferecendo mais praticidade no seu dia a dia.</p>

                        <h3>O que isso significa na prática?</h3>
                        <p className="text-muted">Embora proporcionemos acesso a avaliações, recomendações e perfis detalhados, é importante esclarecer alguns pontos:</p>

                        <ul>
                          <li>Não temos como garantir a qualidade específica dos serviços prestados, apesar das avaliações disponíveis;</li>
                          <li>Não podemos assegurar o caráter da profissional;</li>
                          <li>Não podemos garantir que todos os acordos serão integralmente cumpridos.</li>
                        </ul>

                        <h3>Por isso, aqui vão algumas dicas para ajudar na sua escolha:</h3>

                        <ol>
                          <li>Confira as avaliações e recomendações da profissional;</li>
                          <li>Verifique se ela possui um vídeo de apresentação no perfil;</li>
                          <li>Observe se há selos de qualificação na conta dela;</li>
                          <li>Cheque o último acesso para saber se o perfil está ativo;</li>
                        </ol>

                        <p>E por fim, se houver qualquer dificuldade em estabelecer contato, de acordo com nossa funcionalidade de falhas, nos avise! Adicionaremos mais créditos à sua conta como forma de compensação.</p>
                      </div>
                      <div className="p-2">
                        <button className="text-uppercase fw-bold barlow mb-2 btn btn-block w-100 rounded-pill btn-primary btn-lg" onClick={() => actionCard('ligar', model, tel) }>
                          realizar ligação
                        </button>
                        
                        {
                            tel.whatsapp && <button className="text-uppercase fw-bold barlow mb-2 btn btn-block w-100 rounded-pill btn-success btn-lg" onClick={() => actionCard('enviarWhatsApp', model, tel, props.user) }>
                              enviar whatsapp
                          </button>
                        }
                        <button className="text-uppercase fw-bold barlow btn btn-block w-100 rounded-pill btn-dark btn-lg" onClick={() => actionCard('notContact', model, tel) } disabled={noContactSend}>
                          {!noContactSend ? `falhou o contato` : `enviando aviso...`}
                        </button>
                      </div>
                    </Drawer>


                    <Drawer anchor='bottom' open={falha} onClose={handleClose}>
                      <form onSubmit={handleSubmit} disabled={noContactSend} className="d-flex flex-column h-100 overflow-hidden">
                        <div className="d-flex align-items-center justify-content-between p-3">
                          <div>
                            <h3 className="m-0">Qual foi o motivo?</h3>
                            <small>A pessoa será avisada ou removida da plataforma e você ganhará mais créditos!</small>
                          </div>
                          <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon titleAccess="fechar modal" />
                          </IconButton>
                        </div>
                        <DialogContent dividers={true}>
                            <fieldset className="">
                              {
                                criterios.map((item, index) => {
                                  return (
                                    <div key={index} className="mb-3">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" onChange={()=> onChangeCriterio(item)} name="motivo" id={`radio-${model._id}-${index}`} />
                                        <label className="form-check-label" htmlFor={`radio-${model._id}-${index}`}>
                                          {item}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </fieldset>
                        </DialogContent>
                        <DialogActions>
                          <button type="submit" className="btn w-100 btn-block btn-lg btn-primary text-uppercase barlow fw-bold">{noContactSend ? 'enviando...' : 'Enviar'}</button>
                        </DialogActions>
                      </form>
                    </Drawer>





                  </div>
                </React.Fragment>
              ))
        }
        </div>
      }

      {
        ((!props.user || props?.user?.type === 'CONTRATANTE') && !props.disabledPublishOferta) &&
          <small className={`form-chk-cotacao d-flex mb-2 justify-content-center w-100 text-muted`}>
            <div className="d-flex justify-content-center gap-1 mb-0 align-items-center">
              <input value={props?.model?._id} className="form-check-input m-0" type="checkbox" id={`checkedCotacao-${props?.model?._id}`} onChange={() => toggleCotacao(props?.model)} checked={props?.disparos?.map(item => item._id).includes(props?.model?._id)} />
              <label className="form-check-label text-uppercase d-flex align-items-center lh" htmlFor={`checkedCotacao-${props?.model?._id}`}>publicar oferta</label>
            </div>
          </small>
      }

      <input className="toogle-heart" id={`toogle-heart-${props?.model?._id}`} name={`toogle-heart-${props?.model?._id}`} type="checkbox"/>
      <label className="toogle-heart-label" htmlFor={`toogle-heart-${props?.model?._id}`} aria-label="like">❤</label>

    </>
  );
};

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.authentication.token,
    token: state.authentication.token,
    user: state.authentication.user,
    colaboradores: state?.authentication?.colaboradores,
    disparos: state?.authentication?.disparos
  }
);

export default connect(mapStateToProps, actions)(phoneButton);